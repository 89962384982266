import { getGatsbyImageData } from "@imgix/gatsby"
import { CmsGuideLayout } from "../../../components/CmsGuideLayout"
import { lorem } from "../../../lib/lorem"
import { PageDataBodyInteriorPageHero } from "../../../slices/PageDataBodyInteriorPageHero"

const Component = () => {
	return (
		<CmsGuideLayout>
			<PageDataBodyInteriorPageHero
				heading={lorem.words(3)}
				coverImage={getGatsbyImageData({
					src: "https://images.unsplash.com/photo-1449824913935-59a10b8d2000",
					width: 700,
					layout: "fullWidth",
					aspectRatio: 891 / 594,
				})}
			/>
		</CmsGuideLayout>
	)
}

export default Component
